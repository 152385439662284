import * as React from 'react';
import { css } from '@emotion/react';
import { Footer } from '../components/Footer';
import { Link } from 'gatsby';
import { Header } from '../components/Header';

const NotFoundPage: React.FC = () => {
  return (
    <>
      <Header />
      <main
        css={css`
          padding: 3rem;
          flex-grow: 1;
          h1 {
            font-size: 3rem;
            font-weight: 500;
            margin: 0;
          }
        `}
      >
        <h1>Page Not Found</h1>
        <p>
          Go back to the <Link to="/">home page</Link>.
        </p>
      </main>
      <Footer />
    </>
  );
};

export default NotFoundPage;

export const Head = () => {
  return <title>Page Not Found - Green Chestnut</title>;
};
